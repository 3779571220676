import {ecomAppDefID, membersAppDefId, Events} from './constants';
import {Experiments} from '../common/experiments/Experiments';
import {createStoreFrontBILogger} from '@wix/wixstores-client-core/dist/src/bi/configure-front-bi-logger';
import {Logger} from '@wix/bi-logger-ec-sf';
import {getAppManifest} from './services/appManifest';
import {PageMap} from '@wix/wixstores-client-core/dist/es/src/constants';
import {DependantApps} from './services/dependantApps';
import {sliderWidthMigration} from './services/sliderMigration';
import {adiMissingPagesMigration} from './services/adiMissingPagesMigration';
import {translateFunctionFactory} from '../common/translations/translations';
import {AppApiModel} from '@wix/wixstores-client-core/dist/src/types/app-api-model';

let appToken;
let options;
let t;
let locale: string = 'en';
let biLogger: Logger;
let sdk: IEditorSdk;
// @ts-ignore
// tslint:disable-next-line:no-unused
let experiments: Experiments;
let dependantApps: DependantApps;
let storeId: string;

async function getExperiments(instance: string): Promise<Experiments> {
  const experimentsResponse = await fetch('/_api/wix-ecommerce-storefront-web/api', {
    method: 'post',
    body: JSON.stringify({
      query: require('!raw-loader!../common/experiments/getConfig.graphql'),
      source: 'WixStoresWebClient',
      operationName: 'getConfig',
    }),
    headers: {
      'X-ecom-instance': instance,
      Authorization: instance,
      'Content-Type': 'application/json; charset=utf-8',
    },
  })
    .then(data => data.json())
    .then(data => {
      return (data.data.experiments || []).reduce((acc, e) => {
        acc[e.name] = e.value;
        return acc;
      }, {});
    });
  return new Experiments(experimentsResponse);
}

async function addStoresPagesAsPanel() {
  const tpaApplicationId = (await sdk.tpa.app.getDataByAppDefId(appToken, ecomAppDefID)).applicationId;
  const allSitePages = await sdk.pages.data.getAll();
  const storesPages = allSitePages.filter(page => page.tpaApplicationId === tpaApplicationId);
  return Promise.all(
    storesPages.map(async page => {
      const pageRef = {id: page.id, type: page.type};
      const pageData = await sdk.pages.data.get(appToken, {
        pageRef,
      });
      if (!pageData.managingAppDefId && pageData.tpaPageId !== PageMap.ORDER_HISTORY) {
        return sdk.pages.data.update(appToken, {
          pageRef,
          data: {managingAppDefId: ecomAppDefID},
        });
      }
    })
  );
}

export async function setStateForPages() {
  const applicationPages = await sdk.document.pages.getApplicationPages(appToken);
  applicationPages
    .filter(({managingAppDefId}) => managingAppDefId === ecomAppDefID)
    .forEach(pageData => {
      if (
        pageData.tpaPageId === PageMap.PRODUCT ||
        pageData.tpaPageId === PageMap.CART ||
        pageData.tpaPageId === PageMap.THANKYOU
      ) {
        sdk.document.pages.setState(appToken, {
          state: {
            [pageData.tpaPageId]: [{id: pageData.id}],
          },
        });
      }
    });
}

export const waitFor = (time = 3000) => {
  return new Promise(resolve => {
    setTimeout(resolve, time);
  });
};

export const showProgressBar = (shouldInstallMembers: boolean = true): Promise<Function> => {
  let currStep = 1;

  return new Promise(async resolve => {
    const panelRef = await sdk.editor.openProgressBar(appToken, {
      title: shouldInstallMembers ? 'Installing members...' : 'Installing wishlist...',
      totalSteps: shouldInstallMembers ? 3 : 2,
      currentStep: currStep,
      stepTitle: 'Preparing the app',
    });
    await waitFor(7000);
    if (shouldInstallMembers) {
      await sdk.editor.updateProgressBar(appToken, {
        panelRef,
        currentStep: ++currStep,
        stepTitle: 'Just a few seconds more',
      });
      await waitFor(5000);
    }
    await sdk.editor.updateProgressBar(appToken, {
      panelRef,
      currentStep: ++currStep,
      stepTitle: 'All done!',
    });
    await waitFor(500);
    resolve(() => {
      sdk.editor.closeProgressBar(appToken, false, {panelRef});
    });
  });
};

export const appApi: AppApiModel = {
  isMembersInstalled: () => {
    return dependantApps.isMembersInstalled();
  },
  installMembersAreaAndWishlistPage: async () => {
    const progressBarPromise = showProgressBar(true);

    await dependantApps.installMembers();

    await dependantApps.installWishlistPageInMembersArea();

    const close = await progressBarPromise;
    await waitFor(1000);
    close();
    sdk.editor.showNotification('', {
      message: 'Members installed!',
      type: 'success',
    });
  },
  installWishlist: async () => {
    const progressBarPromise = showProgressBar(false);

    await dependantApps.installWishlistPageInMembersArea();

    const close = await progressBarPromise;
    await waitFor(1000);
    close();
    sdk.editor.showNotification('', {
      message: 'Wishlist installed!',
      type: 'success',
    });
  },
  uninstallWishlist: async () => {
    await dependantApps.uninstallWishlistPageInMembersArea();

    sdk.editor.showNotification('', {
      message: 'Wishlist removed!',
      type: 'success',
    });
  },
};

export const editorScript = {
  editorReady: async (_editorSDK, _appToken, _options) => {
    options = _options;
    appToken = _appToken;
    sdk = _editorSDK;
    const instance: string = await (sdk as any).document.info.getAppInstance('token');
    const encodedInstance = instance.substring(instance.indexOf('.') + 1);
    const parsedInstance = JSON.parse(atob(encodedInstance));
    storeId = parsedInstance.instanceId;
    const isMerchant = true;
    biLogger = createStoreFrontBILogger({uuid: parsedInstance.uid}, parsedInstance.biToken, {
      storeId,
      isMerchant,
      appName: 'wixstores worker',
    });
    experiments = await getExperiments(instance);
    dependantApps = new DependantApps(sdk, appToken, biLogger, options);
    locale = await sdk.editor.environment.getLocale();
    t = await translateFunctionFactory(locale);

    await addStoresPagesAsPanel();
    await setStateForPages();
    await dependantApps.installApps();
    sdk.editor.setAppAPI(appToken, appApi);
  },
  getAppManifest: () => getAppManifest(t, locale, appToken),
  onEvent: async data => {
    const {eventType, eventPayload} = data;
    switch (eventType) {
      case Events.manageStores:
        return sdk.editor.openDashboardPanel(appToken, {url: 'store/products', closeOtherPanels: false});
      case Events.deletePage:
        const {pageRef} = eventPayload;
        return sdk.pages.remove(appToken, {pageRef});
      case Events.addShopPage:
        await sdk.tpa.add.component(appToken, {
          appDefinitionId: ecomAppDefID,
          page: {pageId: 'product_gallery'},
          componentType: 'PAGE',
        });
        return addStoresPagesAsPanel();
    }
  },
  handleAction: ({type, payload}) => {
    try {
      switch (type) {
        case 'appInstalled':
          switch (payload.appDefinitionId) {
            case membersAppDefId: {
              return dependantApps.onMembersInstall();
            }
            default:
              return Promise.resolve();
          }
        case 'migrate':
          switch (payload.type) {
            case 'sliderWidth':
              return sliderWidthMigration(sdk, appToken);
            case 'adiMissingPages':
              return adiMissingPagesMigration(sdk, appToken, storeId, biLogger);
            default:
              return Promise.resolve();
          }
        default:
          return Promise.resolve();
      }
    } catch (e) {
      return Promise.reject(e);
    }
  },
  getControllerPresets: () => Promise.resolve([]),
};
